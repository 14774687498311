<template>
  <div>
    <app-card>
      <template #body>
        <app-table request-url="/api/users/hr" :fields="fields" :filters="filters" :row-class="rowClass">
          <template #cell(actions)="data">
            <b-button variant="outline-info" size="sm" @click="createUser(data.item)">
              {{ $t('btn.create_user') }}
            </b-button>
          </template>
          <template #cell(person_id)="data">{{ `# ${data.value}` }}</template>
        </app-table>
      </template>
    </app-card>
    <portal to="subheader-toolbar">
      <filters-form @submit="filters = $event"/>
      <b-button variant="transparent-white" @click="$router.push({name: 'usersList'})">
        {{ $t('btn.back_to_list') }}
      </b-button>
    </portal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import FiltersForm from '@/views/users/components/CreateFiltersForm';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    FiltersForm,
    AppTable
  },
  data() {
    return {
      filters: null,
      fields: [
        {key: 'actions', label: ''},
        {key: 'firstName', label: this.$t('label.name')},
        {key: 'lastName', label: this.$t('label.last_name')},
        {key: 'email', label: this.$t('label.email')},
        {key: 'phone', label: this.$t('label.phone')},
        {key: 'personalId', label: this.$t('label.person_id')},
        {key: 'dep', label: this.$t('label.department')},
        {key: 'pos', label: this.$t('label.position')}
      ]
    }
  },
  methods: {
    createUser(data) {
      this.$store.dispatch('usersStore/CREATE', data).then(response => {
        this.$router.push({name: 'updateUser', params: {id: response?.data?.id}});
      });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return false;
      if (item.created) return 'bg-success-o-30';
    }
  }
}
</script>