<template>
  <app-filters :filters="form" @submit="$emit('submit', $event)">
    <template #body>
      <app-input
          v-model="form.person_id"
          :label="$t('label.person_id')"
          :placeholder="$t('placeholder.enter_person_id')"
      />
      <app-multiselect
          :label="$t('label.department')"
          :placeholder="$t('placeholder.select_department')"
          :value="departments.filter(el => el.id === form.department_id)"
          :options="departments"
          @select="form.department_id = $event.id"
          @remove="form.department_id = null"
      />
    </template>
  </app-filters>
</template>

<script>
import {mapGetters} from 'vuex';
import AppFilters from '@/components/filters/AppFilters';
import AppInput from '@/components/form-groups/AppInput';
import AppMultiselect from '@/components/form-groups/AppMultiselect';

export default {
  components: {
    AppFilters,
    AppInput,
    AppMultiselect
  },
  data: () => ({
    form: {
      person_id: null,
      department_id: null
    }
  }),
  beforeMount() {
    this.$store.dispatch('usersStore/GET_DEPARTMENTS');
  },
  computed: {
    ...mapGetters({
      departments: 'usersStore/DEPARTMENTS'
    })
  }
}
</script>